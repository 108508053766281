import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import SEO from '../components/seo/seo'
import Layout from '../components/layout/layout'
import IsVisible from 'react-is-visible'
import Icon from '../components/icon/icon'
import Masthead from '../components/masthead/masthead'
import {linkResolver} from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'

export const query = graphql`
  query RiskFinanceQuery($uid: String) {
    prismic {
      allRiskfinances(uid: $uid) {
        edges {
          node {
            _meta {
              id
              uid
              type
            }
            metatitle
            metadescription
            summary
            title
            image
            subtitle
            link {
                ... on PRISMIC__ExternalLink {
                    url
                }
            }
            linktext
            contentblocktext
            contenttabletitle
            contenttableitemtitle
            contenttableitemtext
            contenttableitemtitle2
            contenttableitemtext2
            contenttableitemtitle3
            contenttableitemtext3
            quote
            icontabletitle
            icontableitemicon
            icontableitemtext
            icontableitemicon2
            icontableitemtext2
            icontableitemicon3
            icontableitemtext3
            footercontent
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  const doc = data.prismic.allRiskfinances.edges.slice(0, 1).pop()
  if (!doc) return null
  const title = RichText.asText(doc.node.title)
  const description = RichText.asText(doc.node.summary)
  const metaTitle = doc.node.metatitle ? RichText.asText(doc.node.metatitle) : title
  const metaDescription = doc.node.metadescription ? RichText.asText(doc.node.metadescription) : description
  return (
    <Layout footerClass="c-footer--minimal">
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={`${ doc.node._meta.uid }`}
      />
      <Masthead title={title} inlinecontent={description} cssClass="c-masthead--condensed"></Masthead>
      <div className="c-masthead-feature">
        <div className="c-masthead-feature__bg-image-container">
          <div className="c-masthead-feature__bg-image-wrapper">
            {doc.node.image && (
              <img src={ doc.node.image.low.url } data-src={ doc.node.image.url } className="c-masthead-feature__bg-image lazyload" alt=""/>
            )}
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="c-masthead-feature__sub-content-container">
              <div className="c-masthead-feature__sub-content-wrapper">
                <div className="c-masthead-feature__sub-content cms-content">
                  {RichText.render(doc.node.contentblocktext, linkResolver, htmlSerializer)}
                </div>
              </div>
            </div>
            <div className="c-masthead-feature__content-container">
              <div className="c-masthead-feature__content cms-content">
                <p>{ RichText.asText(doc.node.subtitle) }</p>
              </div>
              {doc.node.link && (
                <div className="c-masthead-feature__links">
                  <a href={doc.node.link.url} className="c-masthead-feature__content-link c-btn">
                    {RichText.asText(doc.node.linktext)}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="c-product-feature c-product-feature--list-only">
        <div className="container-fluid">
          <div className="row">
            <div className="c-product-feature__list-header">
              <h2 className="c-product-feature__list-header-title">{ RichText.asText(doc.node.contenttabletitle) }</h2>
            </div>
          </div>
          <div className="row">
            <div className="c-product-feature__list-item">
              <div className="c-product-feature__list-wrapper">
                <h3 className="c-product-feature__list-title">
                  { RichText.asText(doc.node.contenttableitemtitle) }
                </h3>
                <div className="c-product-feature__list-summary cms-content">
                  {RichText.render(doc.node.contenttableitemtext, linkResolver, htmlSerializer)}
                </div>
              </div>
            </div>
            <div className="c-product-feature__list-item">
              <div className="c-product-feature__list-wrapper">
                <h3 className="c-product-feature__list-title">
                  { RichText.asText(doc.node.contenttableitemtitle2) }
                </h3>
                <div className="c-product-feature__list-summary cms-content">
                  {RichText.render(doc.node.contenttableitemtext2, linkResolver, htmlSerializer)}
                </div>
              </div>
            </div>
            <div className="c-product-feature__list-item">
              <div className="c-product-feature__list-wrapper">
                <h3 className="c-product-feature__list-title">
                  { RichText.asText(doc.node.contenttableitemtitle3) }
                </h3>
                <div className="c-product-feature__list-summary cms-content">
                  {RichText.render(doc.node.contenttableitemtext3, linkResolver, htmlSerializer)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-content c-content--risk">
        <div className="container-fluid">
          <div className="row">
            <div className="c-content__container">
              <blockquote className="c-content__quote-container">
                <p className="c-content__quote">{ RichText.asText(doc.node.quote) }</p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
      <div className="c-product-feature c-product-feature--list-only">
        <div className="container-fluid">
          <div className="row">
            <div className="c-product-feature__list-header">
              <h2 className="c-product-feature__list-header-title">{ RichText.asText(doc.node.icontabletitle) }</h2>
            </div>
          </div>
          <div className="row">
            <div className="c-product-feature__list-item">
              <div className="c-product-feature__list-wrapper">
                <IsVisible once>
                  {isVisible =>
                    <div className={`c-product-feature__list-icon${ isVisible ? ' is-visible' : '' }`}>
                      <Icon icon={doc.node.icontableitemicon}></Icon>
                    </div>
                  }
                </IsVisible>
                <div className="c-product-feature__list-summary cms-content">
                  {RichText.render(doc.node.icontableitemtext, linkResolver, htmlSerializer)}
                </div>
              </div>
            </div>
            <div className="c-product-feature__list-item">
              <div className="c-product-feature__list-wrapper">
                <IsVisible once>
                  {isVisible =>
                    <div className={`c-product-feature__list-icon${ isVisible ? ' is-visible' : '' }`}>
                      <Icon icon={doc.node.icontableitemicon2}></Icon>
                    </div>
                  }
                </IsVisible>
                <div className="c-product-feature__list-summary cms-content">
                  {RichText.render(doc.node.icontableitemtext2, linkResolver, htmlSerializer)}
                </div>
              </div>
            </div>
            <div className="c-product-feature__list-item">
              <div className="c-product-feature__list-wrapper">
                <IsVisible once>
                  {isVisible =>
                    <div className={`c-product-feature__list-icon${ isVisible ? ' is-visible' : '' }`}>
                      <Icon icon={doc.node.icontableitemicon3}></Icon>
                    </div>
                  }
                </IsVisible>
                <div className="c-product-feature__list-summary cms-content">
                  {RichText.render(doc.node.icontableitemtext3, linkResolver, htmlSerializer)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-content c-content--risk">
        <div className="container-fluid">
          <div className="row">
            <div className="c-content__container">
              <div className="c-content__text cms-content">
                {RichText.render(doc.node.footercontent, linkResolver, htmlSerializer)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
